import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/layouts/Default/index.js";
import { IoChevronUpCircle } from 'react-icons/all';
export const metadata = {
  title: 'Design Guidelines'
};
export const _frontmatter = {};
const layoutProps = {
  metadata,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`CheaprEats - Design Guidelines`}</h1>
    <h2>{`DESIGN GUIDELINES`}</h2>
    <p>{`Logo and Branding Information`}</p>
    <h5>{`Logo Downloads`}</h5>
    <h3>{`CHEAPREATS LOGO`}</h3>
    <p>{`The CheaprEats logo uses the brand’s primary font, Quicksand, and the primary colour, CheaprEats Red or #EE2434.
The white variant is to be used in situations where the background colour is red itself, and requires a contrasting logo colour.
Often, the logo is accompanied by the text "CheaprEats" in the font Quicksand Bold. If text is used, it can be followed by with the company slogan, "Order now, Pick up later!" on the consecutive line with CheaprEats accent typeface Raleway Bold. The text should be inline with the icon as shown.
The logo must never be recreated or modified. It must never be stretched out of proportions. If the icon is used, mention of CheaprEats must be visible in its vicinity so that the logo is attributed to CheaprEats. It is always used in full colour, unless production restrictions prohibit it, in which case, the logo shall be white to contrast on dark colours and red to contrast with light colours.
To download individual logo assets, please click on the variants provided below.`}</p>
    <h5>{`Download Icon Variants`}</h5>
    <a href="../images/logo-red.png" download="cheapreats-logo-red">
    <img src="../images/logo-red.png" />
    </a>
    <a href="../images/logo-white.png" download="cheapreats-logo-white">
    <img src="../images/logo-white.png" />
    </a>
    <h5>{`Download Text Variants`}</h5>
    <a href="../images/logo-text-red.png" download="cheapreats-logo-text-red">
    <img src="../images/logo-text-red.png" />
    </a>
    <a href="../images/logo-text-nameonly-red.png" download="cheapreats-text-nameonly-red">
    <img src="../images/logo-text-nameonly-red.png" />
    </a>
    <a href="../images/logo-text-white.png" download="cheapreats-logo-text-white">
    <img src="../images/logo-text-white.png" />
    </a>
    <a href="../images/logo-text-nameonly-white.png" download="cheapreats-text-nameonly-white">
    <img src="../images/logo-text-nameonly-white.png" />
    </a>
    <br />
    <a href="../images/logo-text-square-red.png" download="cheapreats-logo-text-square-red">
    <img src="../images/logo-text-square-red.png" />
    </a>
    <a href="../images/logo-text-square-white.png" download="cheapreats-logo-text-square-white">
    <img src="../images/logo-text-square-white.png" />
    </a>
    <h3>{`Logo Guidelines`}</h3>
    <h5>{`SIZING & CLEAR SPACE`}</h5>
    <p>{`Whether the logo is used alone, provided that the CheaprEats text is in the vicinity, or used together, the following exclusion zones must be adhered to. If the text variant is used, the C must line up with the dot next to the leaves.`}</p>
    <h3>{`Logo Don'ts`}</h3>
    <h5>{`SAMPLE GUIDELINE CONTRADICTIONS`}</h5>
    <div {...{
      "style": {},
      "'flex'}}": ""
    }}>{`
    `}<div parentName="div">{`
        `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "512px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/1d8fc4de064fcb3f6b07a5bc3a295796/01e7c/effects.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFDElEQVQ4y01Ua2xTZRh+e0rXbW5sHaNbW9rTc2m79d5zepkMFIdCFPYDMBJBIjGGAAElUUQWg4YoMUEjCovIxcQQEiBRjDCdeAkEEKaMu4ADhewClPWyDZgM2D6fYztjkyff6Xd5vud93+d7KS1Hy4APgGlpSaW0FOWOOHy0uKKaNlQL9IcYMl6VI5ZBUaEhKarrlRQd9nEZOUo4Uw+0AocAH0DapAk4B1wEPNrkeWeQY/VzdSw8nZrN/PYBOZpmtYlSNnkOZcUw9WEPCJ9Ny2pHRlJv48wXwCM5Qk2VHF2ChQGMe4ECyv/0OnLvscjdO6rF4RtiZNPmSp76xHBZVo6uAll3RoomsX87UJjOKdZR/sMAfJknbWLex8hbUEjTisuWneH9J6wFxiX7bZ7uZrNzbVJSkgDD3n7sfbtXCI9ycKMhc/mJYEYLW1KvXReVp3daJJIMxkXtDv+lUmNhfJdFvnnI7m3rFCOZHjHCEPaqu64YZXIRjnJQbkJS9ancuBDo65XU46xmYuPMkvIXPq8Ss7st8uZjDl8/c9e13xCVhylJ2XdHVGlIULkkwswXKEfYLUaIuRNUYyyizdUi9cvRD29JagYXMKhpChqLmi85Qwz/B26KCrslKqezktLAPAmJ1U7SzuqGoTRdm8glflaJidikuYRFvUa81+papZFBZfqOHNvxs7123TUhPIKLj++xuDY8N7aiaoWp+mhSjNxk4ScKWeBxiFB1Sb2NBjZuyZE2VVi4103V9HzpuKfaHb5O5o7PgB8PIPxOWKYLY3bIFd/IVm/jWqxu7YjypsmSvStHtw4i9G5niOt6+UVKO4JEBqyyZdt0bMZy+qxKaEOi38kin1D5PkJ/gHHkqhAe2FolZI7ZvakRV2zqorLxGumCb6xuxpTpIgs2wJeqLu0IEBXp4bbcz/OtzdPH4jPNSUlt7BGVQZDdguf2oqIXT/GB1sVl5gu/84EUmzKvHPsN6yonZJinbglzJSgpRPTpwESiSFEJ5yssJrexaNaBCTUdyGUDfHYe1WQYXznJ+yejioPI5zDy2N/hDHUxb/14FptJK02WI9cl5ZMr8GKXCEJZhSxjMVc5xkCmMYbZrVA44k4cxcGui84gO8kHZs8vHbfzFO8/+KcQboHSFIp1AqrnsdCTtLrC+gMIN2JNc4s+7YJtijk9lw850mLzPETyswh197zScT9ecYbOLq+oKmX+KZQSInSaD3wP3zIUquc33v8uzH/lvju+4IEco15RGaM9Y20TdxTdpc3h910WQoO4fYB5J4VwwQRUPnvZGbp03xV7q1sIb7rABzRjbwLhGbymFDB0V45NHdR8KMdyxoaRuXuuOCFHr/4lhP9GRRmreVRFU9BU83NKKj761Oxs3Wdzf9zO+11IBR20e2PYew8puA5V25HjGf91GygiVLUQCzvx/dPicvPhNofvwbA7/tIbJgsdtnuJBacSw6UdINtqlQpO2H27eoTwV/1yTOuhK0G0Bpivkf4rEzfUIm97ht2JpVBVsHBs5cFOIfzaerNDwSt4j9XWNwy5441JUVl7VQidQ/jNwQIz3daagxw1gsMOWLSuNfqo67DQhIp5V0AVYwxPsZ4aS8rXf2113dhn85yDpX79zubZgqcZRecm5qsjVFz//8Yw2r408ICKfI5lsUZizyzVsf1My6HmAM34Bramhbpgj1+0FMhx7qzTTxeQAs17WmPNg/4B4chJRTuZ1ogAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "effects",
              "title": "effects",
              "src": "/static/1d8fc4de064fcb3f6b07a5bc3a295796/01e7c/effects.png",
              "srcSet": ["/static/1d8fc4de064fcb3f6b07a5bc3a295796/63868/effects.png 250w", "/static/1d8fc4de064fcb3f6b07a5bc3a295796/0b533/effects.png 500w", "/static/1d8fc4de064fcb3f6b07a5bc3a295796/01e7c/effects.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
        `}<p parentName="div">{`Do not add effects to logo.`}</p>{`
    `}</div>{`
    `}<div parentName="div">{`
        `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "512px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/998838556a9570ccbe6f0e261ab03c52/01e7c/recolor.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFPElEQVQ4yz1UCWwUZRR+u+VSlAZooWVb2t2Z+Wev2WNmti2UKqXQaoAQjGIULVfVFBEBJUBBStSAByIpIoiQSjgsIlQCyqEBIZAomB5CWluh97G9lnaxB7Rl/GaLTvLl5Z//f9///ve996iDV8OB7UB6gFMpwCnGB1E+Si91kq/WRklVtsfVOluc0M5RxKDF8M5Bp+HsQo/xrkU/qyZ38Mo54DL8HQARFmMDvHoTthw/RFjqileNsf/wYayNJ4efLRfbhAdTahwREzUbkcbRLws8OtnzOFsJew/2IHxHPyLETbyajY0gbCE2R2iURBqZacWlp422VlZgaxG+s/uFvaSZQGiOKp4hb2wXlAb4tOD8AWBUiIdTDRR6Jq8Ox48T2AgCOdo4HxzHk6uRWUB2/u3zKU/Anpr0gL20Jc/RXpYsa/UuJYiX5JIWo/vrMOpceoRGnR1ELvwoB2oanUrG9q0SZR93jrG3CL9uujB75ORu/lhBtvvw/Si1unyq3FeaKq8/s8iDlyTqvsbAUIQg5EJkYUOCqItbRaWzd5J6dUuecxFpjEmNLN8SZBv2rXcV9UWrVaXTvb3rvnYU7s5x0Fv5zNhnSjBowxOoQ1B0nqEc1rkV3LQJmEcDkb4dnWa1/dRit5b5o30dafGmrdudA36bErz+jFfLPGP7bYTGZ6SUW72T+jgy9XDGiIccTa12Uuir8cqUm+egnD32YUt+sNH8K9ZFZ1/2aD0m1d8dox6FfbONqQEt3Hfpqw2upaSNG6FWi5nWVqF9SpV9rKfBSu5G0aAhnNw/lxLlnEsnb71o8FWLlFZqi4u7x1/f+b40F0QXoGQdUuC/nuHtyjpp/xbRhpNmIa6DI+R2LYQ6NrnLTDHBeOMb154mqc6CEDUioZ0PA3CIfeytYx9u3uWgFquyrVpR7lckyQPX5nhrZ5SIX5q7+Bu+O2y+1Mzg9xjZWoUrUrPoc2LtbhKNUj1HtKAoiSK7YwwxnXEkNbFLiCBeG+GbA6UDZdNkPwQq1Mb6KjRK+Cb3C+erpl7+TkaRPZ4PCHoAHzla2Dq+g9ejDkur8BCllUk0u9hFWT95wmN7+MITr7nngaS4MlHWSmbIK9JKrdMKlrsGqxRFK0mV27JO2opxaZQ5KOh1ugykuyx3OYrvtBiVOnTS9NtuhO8AXCMT/2YX8cRbEKBiT47UZeoT5kb3sxS5lh1CxEdRVh0Px/t+RxUsBDEJd1mWq4nlmUEYG7QYl56G0pWpU6k7Wja08l76/nX3ZTj2909QC2YWW2cJHcKN3H3eJ5MqBIJYBHEKSlK9GoRq1QwJq5Rqlh/TJyyLHORo7lVxWG806hDTxdAfqRJu5hskpa7Gq/SgUBN3bXaSt5atRnkU4WmfW9uEfVyn8DMmzWdouVtIS+OpJe7g4ZXuWTs/kKBtCv3fevoowq1rUCpNe3OkexMHhZkTBvS8cMiT6ESe1kCwbF+N1fTpNt05UTqd6W77a4rcDOL9qIjZSMPoEOGjHhzVxpQj2Lz43m7ncyiPCncDe1eAeoiQUG8hWDp5WnncMxri7ffUsyMYIunwW4uW2wws1EcYPRoMNpAWNNuV1cllqKlG9qw+B5V6O4fodjv94gtOP3sFI2wHiP9Af3/y4qEIKk6T6eZT8kj4xoIjGlzD/yNMAjYibJueC3SEXmPkabRKiPAIyPOxPgCs8jSIZn16k6YQ8hlWmSSHcjfEMzSxdcQBCjCmxiMTBgAGqxDmahaJhTpICD1d7yaUCVkCXFjybQdNq7BRkyPkbxiCSv8C29RwMHMB63kAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "recolor",
              "title": "recolor",
              "src": "/static/998838556a9570ccbe6f0e261ab03c52/01e7c/recolor.png",
              "srcSet": ["/static/998838556a9570ccbe6f0e261ab03c52/63868/recolor.png 250w", "/static/998838556a9570ccbe6f0e261ab03c52/0b533/recolor.png 500w", "/static/998838556a9570ccbe6f0e261ab03c52/01e7c/recolor.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
        `}<p parentName="div">{`Do not recolour logo.`}</p>{`
    `}</div>{`
    `}<div parentName="div">{`
        `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "512px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/90c779fadbf3bbf500cf14125e50de9c/01e7c/contrast.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsTAAALEwEAmpwYAAACTElEQVQ4y21U2VLbQBDUr8S27vuwsU0wEK5AcCCWsU2S/3/sb0j3ruSDUDWlknanZ3p6ZuTgl4t5iocQmyHaEda98f3Vw9vRJx0eA1QFigLPunIEzkqkFZY+3oad33aIbxGCSrcWT+RdiKREUGOSYeXy0NHpVQy/Rp3bo87GGeoMs0SBXl1cxAhrIc8M0kR05MePJtfFRaJYvPjpocrQemgyJYwquI0cviZoDwmcrpgfPpJKTg8B3gcKV+ZYexjnosBzr8HChF6PjsCHIg35vMRtiBdPhV1FksOmZazdqaIdeK/nLFVhX8ZYxFi68Gr4DYYN0kKMpOinYJv8JsTA1Ea2VkV2hS+s4obil1iNjvE9mEekmueSpyxElRR8oy0VYlwaBZ+kevlYM+uhkucJ/g5wnoow6wwrgfMC90GHKXJJ28vWZ96MJAk7dB+KdmjaHleY5LiOEBfixS7ME1zGe+V68MpTq+8CjRoVYp1PgfLznYHSUnPybsZuZpifgFvTWLaaxlKZ/3ugDtP4mVWC7QZ6zv8HkzaFZZ3UmV2hcrz+M1A2NpzNY3727ywV/gTMfVh6uibPJ18A+mWFKpymYkSqZBQYB4Y+CGbHcxF3vXn0pQeJMMrCyMO4OzO/XDKrP4vqtsouBtmSNoeEw0R5yYUV/jZmtZ0mWhLCmIb7w5lZuY78nn1JbXvAIbmM1Bj2nK78pCtbzd20W81M7OiLS40dzRYZ0oMh7KgxBH8U4xTTTGaZb/v/xH4iRdtuL/H2p9EasyG2vW0+7pO1f0shIDbrE7w5AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "contrast",
              "title": "contrast",
              "src": "/static/90c779fadbf3bbf500cf14125e50de9c/01e7c/contrast.png",
              "srcSet": ["/static/90c779fadbf3bbf500cf14125e50de9c/63868/contrast.png 250w", "/static/90c779fadbf3bbf500cf14125e50de9c/0b533/contrast.png 500w", "/static/90c779fadbf3bbf500cf14125e50de9c/01e7c/contrast.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
        `}<p parentName="div">{`Do not place logo without sufficient contrast.`}</p>{`
    `}</div>{`
    `}<div parentName="div">{`
        `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "512px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ce2c24e35a5898bde3d53cb1ccb77d26/01e7c/border.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEh0lEQVQ4y1VVa2wUVRQ+++y2u3TLdrvPbnd2Hlto6WNnZrsKxCqBtDSIxB/WxIgBTNAgSTWAGFM12lBJpOgPE6k0NGgUTI0GQ4I1ITTVmpA0aiw+iCUIbVrazoBIHxTq+N3ZXYVNvpwz957z7XnNGZoSFO+0oBz6hat9lIjcIZsjDBnIo8RmD2XcxTHopYUWa9BrtYWCWZtC+KyDb/8ELw8dDwkP48xGB/yxOC6mxnn5Ursv2oLDcInVJpVa7RJ0X19YbB+uWHVnk9sr47kCiAP+z8LSvqt8agwBzUGeWOPy1OE8BVDRqWjlO/NSw+SslD7JDkRHAeV/C1K6Z15U+25L6YM9ZRwZDz1ReDeZaYPt6A1RndBF9aixttV+TZDJabHUMB9/hd1ZOS+lP0H4t4BXjRWrydhxmMYS9YkZXj49knnEMyPIX0wJ8uMzgjIOGMDfmqC83uGL0kCsihpc7kJw1VCuZiGjam0ljH7TReUy0KQLCkF6IftnP+i2Qn4MfKQJ6hXIOUS2H6AlqYGMvWZiDpOQFT5gs0u9IYFQj2341xswHIJs1SUlBudjuiC/APLzuqBewvkscErjZdK4lPWykLJE7U6y3EtoI+LzNQPZYZBMwckAyR5dlBnpbeAvwMD5MP5gHe6SWiJFY1y9FVneHyHlCI1khjRBbs2SqVPAp8AuEF3XRPUc5E49KbtA9jTsxnShvuimKOdj+Z/QZbEIL5YE6QpfH4XhtyDcAqJ+OP4JfQJEOvRufWXaiXsy0xXk/UDv9YRMxobnLPcR+lHDzyMSTQvyW3Du0FhDBKUTWIB+F/Iq8CEIhjQ+1YyUacYtMpuzmqjU/RSvodUuT8F/hIWIsMMbInT5a11SOXRyE4w1YBKRfgn8DvQiyq0gHUGzylmUuD+AUrw0ytXSkx5fdmwwjEE2/cbWdidmrQ9Gm2H0A2sAwLqbBua07DNL/0cQhtjIANsQYdfFRB1tdi93mYQOMgkTLFQQfgOHnzUWERphzqOoNEJnzTmhmWfqeeCpLKHyDGy7LnJ19FieENUMLLNYxUO+cpbyAJwWs91VmpDeoJ5MOc0mZFM8rmVHh01AG56PIMLtf8RraUdxWZFJ6MP2gBIxVjXGQIhRUG7BOMMaA8I9wPc4exPogn4G8l3cjwDjbDZht+ECZ/bCjnmupXBuXS0lM7tAOMneFDiuyY8HnGQ470XEuwEuNwHVGmsYs8dyuCmmW3qC/HLwVJvLobagqBrL4RhevXNYAK2o5QXI59mSWMS7im1j4g70rxIrHdN86r0ZPnXymqBsxDLZB9LXRhP125UCd5oRFh/0VzT9k8z0LEgNu09HkmRUPtC0lGzYacjrAyB8w1jx4HqgBYSvzInq2UUp/baHrNQdTJBhGGTIzWXIMBixOdhOJN/Lvkjbr1xdZ6c/1pxbouWAB89bvotVDQ9WVA0MxqoGzkQrj7SXRjfijqWXKLOZS5gDomyNAiojtLwf4GTstMZnvQH2Tpdi+4TjdmcEeglgZQW/B8vK7Y5wbn4DTLrxacDnIYxL97/DgusA3emjEgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "border",
              "title": "border",
              "src": "/static/ce2c24e35a5898bde3d53cb1ccb77d26/01e7c/border.png",
              "srcSet": ["/static/ce2c24e35a5898bde3d53cb1ccb77d26/63868/border.png 250w", "/static/ce2c24e35a5898bde3d53cb1ccb77d26/0b533/border.png 500w", "/static/ce2c24e35a5898bde3d53cb1ccb77d26/01e7c/border.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
        `}<p parentName="div">{`Do not constrain logo solely with border.`}</p>{`
    `}</div>{`
    `}<div parentName="div">{`
        `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "512px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c25034f040b7e1fbaf7c42ae626d0e54/01e7c/proportions.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD0klEQVQ4y02Ub2jVVRjHz723qTlQSsSt5v7c32/bvd515/2dc50bRpSg0B9iG+UL98Ymai+CqKBaQRBhLyyioIGhg/CN01SQqCRoLfYmEMU1mk7nJCc4t3Mm/cEy0D7P+f3u6MWX5/x5nu/z9xzlQr0afAS2AeUCnXahUa6wWbkNHSKrXGs5b+tL6u/BSLFW3Ket6IR6C/gOjHJWEHs5fAiSXziYZN3qScWgqZSxWQiC6BUbRPc53yl72xQ94B2GphdM2UD/wd2XoNoTciCkLyN/R55CaZlthKgFo3zHwzi7BF6DdNo1603eSWhwoheQ89gcQq6IneiUSiKqQp4gDSEd8E4EzWY7BNN2XV72B8BXrqUszu+Df9g79jXoCUemknI6SaGIwiSKv/l6esKyIeUb81U1RFx+DvJ76M3i+Cz334Lzc2qlWlibS91+8lnhIOWwwu7lLjFA8RzoX+zclEH+DF7n/HOJDDnK/h1KcMU3JaCuAXUlAJstEWFQEqRstsCFkZqOekNvrDV39azvQiRkM6xvQDAOUQ94hro+KkSs00JOWiVlGzule+JpkLTGMBqBfB7iY5Dsg3jOStTNJofR0xA02/XtUtdxbC64bNtyF8aBQVZKJ2QfgImFNV1cmBCiMYiuIW9BtojxCfa9rlVX+fFpLCG12B0GI/aRorqlCpJyJN3MYzDPqNS5MFJJlz90cTfvImchfg/5KcYXibAPQjWj6EEc6a842xunHM/PATDMMEtn5ezFpI503ByLh94cd/nO1dSsnAx6j23aqJK53CPNs7UF8fCYePgGvO1HJTRl5PU4OtON4XbW/yYObvpnJvMY6OdtXZuKZ1B3gCnWq5Rt2CgHp1F4KyH8EeM/pQS2vl3uXmB9Ul4R538BqWuvr2NtLuVLFprHPWG+48FKym9idIb1G56MNEnrBzBkG0pJTb3hu3KfRCsvpzpOWb/P+nu7vig15CBXljc7jeIcg36VLj9xe2uYgpCoIun2J9yL0TA6k8gZ5B1wBCdd6BC13hp/Dtkok3wQ++OXoL/wXvNlP5sYa/k8kK+CbpfTy9CL5OWAnzi750tS7Mq4VrP026xEDiEnuLyMx4OurataCm6DKP4oAr209r9REO3GxiKPYydPs0++sMpvI3PIhXmJ/VNEeHBO+Y9jB+eDjFI35PL/7Wb/mZMyBNHXoHOhRppqGtCtlV8rIdSb2Qyg7H9d66PwHe4jqqPIQXAYgo+R/ejlZbB9owpR3Nh4QiqDLR6M1GqVfBBcZmx9MeXJs/9LeWkdybil/YcQZyi6KeH6Dz1+h4bOmPvRAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "proportions",
              "title": "proportions",
              "src": "/static/c25034f040b7e1fbaf7c42ae626d0e54/01e7c/proportions.png",
              "srcSet": ["/static/c25034f040b7e1fbaf7c42ae626d0e54/63868/proportions.png 250w", "/static/c25034f040b7e1fbaf7c42ae626d0e54/0b533/proportions.png 500w", "/static/c25034f040b7e1fbaf7c42ae626d0e54/01e7c/proportions.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
        `}<p parentName="div">{`Do not stretch or change logo proportions.`}</p>{`
    `}</div>{`
    `}<div parentName="div">{`
        `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "512px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f49150ff29bd12440872d7e3c07a4a43/01e7c/tilted.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADrklEQVQ4y02VXWxURRTH5+7ShYDFGIUgH213b9vt17a7927bbUH6AkbR+NJIMNoXhQRMlBQSooK8AA9NiDFRCAnwYCBpKVFCHwholMCTJo3y6Udi0MaYSLszbWn9AInld+bOdt3knzNz58x//ufMObPK1OYfB4fBs7o2r0BM+6FibjGz76Ay6XY1vXa90qszyvhBfLIlhxWfcC0+F8EV0Cz+sukJcBP8ANKOKGZqc0qnspAHMePnvLnZA8xlHKg/VKuQbeXwnzh8Bv9PwBJL6NTswN7DngMJUWRqAyFgPRBVStdkPZ3KCdFzqLsO5iCcwJ5kzyInxCuFtgB8Cuks9l2dkkOC1RCsg7ATwrROWrLnIXgIURHf+4wPmbrQhS9RRQptzkAbjqN8/BWH8xCNgEEZg2smnV+FHcVnLAozPCoRYCWKmIjQyUCpiceSDHJxmUB2VksofvgbKJj6TjWnyHNToRKyAfA33//B3kTZyxB9YOryy0Q90Xh6VUZJnuJO8i7sMPYj7eeL2EugWkcpeRuif+UwfE5NtnYlIHwadcPMx0hJlYly7VnJnJKUcEymOwHZYggusPk/vt0Gn4Fpvn0HXsB/GxvfQN1rkYDw0C9EMdPbp0x9u4pukY9gjygtrmiSJJ8GdyH9HUyCv1j/0F6MHxxgz3HwPuMN3L4yjQVqtUOEQbimTZxQEaxz5K+4UtgP0Tgh37DF64ffs5ZxBd0N3hJ/XZ2NW8KGTkuqTKXyWByCsMHWpB/sZX5JC0F0QQ/czf4JweuOUOwXpg6S+i7qE5XNXXJ5otDW0TcQ9TqF/Toq2h9Rtt1tvuPIRW0Lvi/h+7EtlVRou8rWoPx00qp6h40NU/1XZeFLd5vDXJDSKzOS7KeYlwqfw8Ov8e9znVRhmrvne18Vn2xSuqpNlBVwPCJdwPgK47PgAviZA1uisggHWLdq2TwA4cIoTaE3T6hrOjxdUxDCERfqLRZCki3Kc+AZ1pZK7sYTVbKZ9VBaT2p1qPTKlAld6zF504V6hw2NNpxUzsFeRDtrV7H78c0yHsR+C06BTfOvjbu1RcbPn+bDZebvoepzKSXIjoETrq+/wm9z9EhYNQnwItgtJQZeFdLSYiM4A3bahm/sWABhDwRbLAkvjl7eZptA1+RipZflf8RrAK2Yryh9LIC9jlgVl2WiN1CaPpm1ROOqXqKJTfVstB1RfojL+Su92IJquQiw1D0UnpEnKZmNW8irnZaa46X2s2WC8l+F56AeAW2ddXhxOpo3AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "tilted",
              "title": "tilted",
              "src": "/static/f49150ff29bd12440872d7e3c07a4a43/01e7c/tilted.png",
              "srcSet": ["/static/f49150ff29bd12440872d7e3c07a4a43/63868/tilted.png 250w", "/static/f49150ff29bd12440872d7e3c07a4a43/0b533/tilted.png 500w", "/static/f49150ff29bd12440872d7e3c07a4a43/01e7c/tilted.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
        `}<p parentName="div">{`Do not change orientation of logo.`}</p>{`
    `}</div>{`
    `}<div parentName="div">{`
        `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "512px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/af7e2b9d6d1658a415c5b41df3dabc4a/01e7c/text-placement.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADVklEQVQ4y22Ua2iPURzHz/+/jdZQ5JJitj3P/v9y2fZ/zvOftBiJNLnM5FJqc38rlzdeMLygtSZNURSJV7wgSqLk1rwYRQnLi7nLdp65FhI+v/Oc/2by1Lff9Xyf3zm/c34q8sPhkafPgL3oTZGvJyFTYDwIgE8sAyYSm4a9A8wEHvZy5HViHegBUikcY1BegZegzRFfAC3ozaAd7MFuN55eYfywFbsOnAefsX8i34GGHKFC2UryDyREuhI5C6RBLfF6sJp4BbIIgiz+I9ivQR/5J8AcuxO4INRCmgfOkfwJuY1EZeIfgWAEst6UBfHP/XAteA5egWbJFQ4TxyxhMk6kMj98DF6A+TGhkOhlLMIXSLwJ/QPoQt9sfxgT5TkOpewfPJ3nKpWm9IL76FNdMmeql4Db5H4i9xe+xjjfFpOQPDOIUByyzVg/KlUQvIk+ErkJnMVnWNQB4c2oPBzrCkjktmq3K1804EjGZ6ZHo1+G4CPyDgtb0X/jj5A3jBdsN8UZ9b6whjWB6stUq6iiRvV/fxFKhfmu0nlGroSn31BBN2QPwFLOtI4frID0oM0rC5LR1KzqLSxRgz5LmM6qHjVOfbtyEcLgAAsu4X+CfA9ZJ0QbTOm0Aum2EOJbhLSkvQXFg8lc6xPuoKtIfoi/E/9b8Ay9WxoCwa2eobLVsJi8w6Y0o0xJVYIz/S9hviNshPArPtnqHZJ1JKRyprbDepNr3mHsEZG7h/82JeHkMHmXLPiGvp/EliiVnczZbcU+HsmZevoRcj2xNnyFxAf1IVdh0rV/F/p3Ftyz2/GCVaC9L1Mr1c+O7AUPhbQH+3pUni38pyD1t5Em8SmyC5JrIOUasBKio+AAlcokapAdgC/oZ8CU3JZjQk/un73lW6L47u1j4QSIriJn2Dccv2nVV1GTxL8THCLvmJHr5IenQZ0Mjv4t27PwtQTukpR2JEE8//RuO8rsj/RJyNaZMlvREGKL3XxsBmuwi3KE5eAUjo22g6lqZe9Y/HL8eNDqlJlQkbsRubGXe66jAC9MFwyMJM9WuBFZhSxDaiPww7FIEIo9Hb/EKt3Eng5kdlYT8wYGrK/nYmy2I92zehr/PORCfNrqPk/PD+fgmwKa4h9rsevBAlAqvfgDpdZocdb/S8wAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "text placement",
              "title": "text placement",
              "src": "/static/af7e2b9d6d1658a415c5b41df3dabc4a/01e7c/text-placement.png",
              "srcSet": ["/static/af7e2b9d6d1658a415c5b41df3dabc4a/63868/text-placement.png 250w", "/static/af7e2b9d6d1658a415c5b41df3dabc4a/0b533/text-placement.png 500w", "/static/af7e2b9d6d1658a415c5b41df3dabc4a/01e7c/text-placement.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
        `}<p parentName="div">{`Do not change logo's text placement.`}</p>{`
    `}</div>{`
    `}<div parentName="div">{`
        `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "512px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/fd992ec8f858e6f16dd5b4fa6f2ae0aa/01e7c/busy-background.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEz0lEQVQ4ywHEBDv7AM+HStZKNtNuQNaeTc2RPrR1MZlII4BLJLWBPdmTQOKrS9GbR71UGKVRHqFvPr5dOOlBN9QrLa4tGphDIQDrsWXqPTz0RkTIdEOcVCTDYSe4YjWtWiflUizsODfHdTq7URuwUB+iUyeqRS/nLjLmIzHaPTHCVBa7cCgA37Fb7YBW7y443kI7yXQmylUQqTcQ5z4R4iYlySst4zQmvjsHzDgR3zYv5ioz5CAv4UswzJAsllgism5AAJxjM5RcNdg3L+onM9lMJ5xfIZRTKMFaL9cvMaFUJ+Q6LsdCKt8jLu0lM+kqMtRJNLZ0OppbNKZgOd2CPgCvaBjMiBjZayLtMjTrKy/TTCilbDaZTibXKiq3YyfjSTfyWUbsMjb1Ljm0Lyx2QCKgaDufVi7ThT3why4Ax18TynQIyY4y4nc/5ikz4ygtujIhrTwSxSwmzyoe4ykv5Sox6ic11y4zvHNAhVQmilEkllkqrVElzz8LAKFJKLNbLp83EchNFd8tIOcmMfIlNcgrKKYsF+smM+slMeshMu01MdNnIb5eKsyDPbVyMZpKJqlsQo5NJQCqc0KPUCy2OQ6sKwKmHRTwIDbpJDDaLSzBKSPkIzDlOzrbRDXaOhrlOSPiJC+wYy/PnELVl0bVjE3DjTMAdkMXYSQZoD4OuUYT2TMt8Yce7Ugs7jQ05ys14SEv5EtB2XVMl0Mil0ARwTEhxS4ol2Yq2aREx4Y2voQ8AMhjEbBGEa1zM+lVM+A4Htl6AupZIeokM+8kM+0vN9VANOI0MIwpHJtFDp1OFs0wLdF4P8+RT7x6TMx9PACrVhevWR6icD3nRDXAPhe8NSDrKDHrLzbbOTXgKTLfRDLnWx/qKi3HTB/BaynLRTPPUzbGi0fXkULbixAAgT8iiVEhs3IZ2zgux0Yq4i0w7B8yxU8ww2Yt6SUv8CQ08C4z5ikutzccznxA4GlBvTUpn20wr3YexHEZAIVHJ5FSK8RyHuA7NOc4O+QsMeooL7FUH8FnJ+AqKdArKeknM+grL7IgFZ9WI9VeP8EzKohWJJFdL7VPIgCiTiW6Ox7fQxfoKSjtKTnVOi+4SiD3SinVjzK7dz2ZXTvMOTfxIDHqLTfJSTvOOjLMLiGpNxTIUx3BXCgAh08o0Ecb7DUP7SYx7SgzvlMtv4pC5Fw47jw73VM81zs14jIw1j0o4DA15So17iYzsikYiiMKlyQNoEciAIdSKJlQItMxIecpM+cmM6otF5tAE7tdHeiCQ7A6Lb42Jt16JMh2KdNOJ+0pNOstONE+MMJCJrJJH5hlMACCUSW6XSPUMSzZMjS/MynYHimpJB+TPRSyaByORxqHLgqzXBvAQBDYIx/YOzTdYkHhWkHQSTDDUziyWCsApoBFqFkx2jMvuCghi0gp3F4822MmoFAixCUf1l45uS4WzjEg4yoq50kjo0sdxmsluG8vyIFGzIVJxjcOAKJnMKRFJr48MKxJKrd4RZ1kL5FXG5A4H9c1MM4yLtE8N+VeQ9s9FNhUDO5cGZ9DGoxRIZVkNqNaIpcuCACERAxzMhVjHxVsNx6HRSOkUCOXRh2XYy63czKsZTyvYjDFYSS+PgO+TxS+Rgm6WCyvbz3NdxGyWAKtQRARG+zKbfUm4AAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "busy background",
              "title": "busy background",
              "src": "/static/fd992ec8f858e6f16dd5b4fa6f2ae0aa/01e7c/busy-background.png",
              "srcSet": ["/static/fd992ec8f858e6f16dd5b4fa6f2ae0aa/63868/busy-background.png 250w", "/static/fd992ec8f858e6f16dd5b4fa6f2ae0aa/0b533/busy-background.png 500w", "/static/fd992ec8f858e6f16dd5b4fa6f2ae0aa/01e7c/busy-background.png 512w"],
              "sizes": "(max-width: 512px) 100vw, 512px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
        `}<p parentName="div">{`Do not place logo on busy/contrast lacking image.`}</p>{`
    `}</div>
    </div>
    <a onClick={() => window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })}>
    <p>BACK TO TOP</p>
    <IoChevronUpCircle mdxType="IoChevronUpCircle" />
    </a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      